<template>
    <v-container class="places-grid" fluid>
        <v-row>
            <v-col v-for="place in getItems" :key="place.ID" cols="12" xl="3" lg="4" md="4" sm="6">
                <place-card :place="place" @redirect="onRedirect"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import * as Format from "@/util/format"
import PlaceCard from "../PlaceCard"

export default {
    components: { PlaceCard },
    props: {
        title: {
            type: String,
            default: "",
        },
        value: {
            type: Array,
            default: () => ([]),
        },
    },
    computed: {
        getItems() {
            return this.value.map(place => ({
                id: place.ID,
                avatar: Format.getMedia(place.Avatar.Cropped, "place"),
                name: Format.getName(place.Name),
                self: place,
                description: Format.getDescription(place.Description, this.$t(`placeholders['no description']`)),
                publiclySearchable: place.Options.PubliclySearchable
            }))
        },
    },
    methods: {
        onRedirect(id) {
            this.$router.push(`/places/${id}`)
        },
    },
}
</script>
