<template>
    <material-card
        v-bind="$attrs"
        class="image-card"
        v-on="$listeners">

        <v-row justify="space-between" class="ma-0">
            <h4>{{ place.name }}</h4>
            <v-icon class="mr-2" :color="iconColor">
                {{icon}}
            </v-icon>
        </v-row>

        <template slot="header">
            <v-img
                min-width="90"
                max-width="90"
                min-height="100"
                max-height="100"
                cover
                @error="onErrorLoadImage"
                :aspect-ratio="1"
                :src="getPicture"/>
        </template>

        <div class="pt-2">
            <div class="subtitle">{{ place.description }}</div>
        </div>

        <template slot="actions">
            <v-btn v-if="canUploadPhoto" :to="`/upload?place=${place.id}`" large color="blue-grey darken-1"
                   class="my-0" icon>
                <v-icon color="blue-grey darken-1">mdi-cloud-upload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text :to="`/places/${place.id}`" color="blue-grey darken-1" class="my-0">{{ $t("actions.transit") }}</v-btn>
        </template>
    </material-card>
</template>

<script>
import { mapGetters } from "vuex"
import MdlCard from "./MdlCard"

export default {
    inheritAttrs: false,
    components: { MaterialCard: MdlCard },
    props: {
        ...MdlCard.props,
        place: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    data() {
        return {
            pictureLoadFailed: false,
        }
    },
    computed: {
        ...mapGetters("common", ["getUserId", "isJesus"]),
        canUploadPhoto() {
            if (this.isJesus) {
                return true
            }

            const admins = this.place.self.Admins || []
            const photographers = this.place.self.Photographers || []

            return admins.includes(this.getUserId) || photographers.includes(this.getUserId)
        },

        getPicture() {
            return this.pictureLoadFailed ? "/images/place-avatar.svg" : this.place.avatar;
        },

        icon() {
            return this.place.publiclySearchable ? 'mdi-eye-outline' :  'mdi-eye-off-outline'
        },

        iconColor() {
            return this.place.publiclySearchable ? 'primary' : ''
        }
    },

    methods: {
        onErrorLoadImage() {
            this.pictureLoadFailed = true
        },
    },
}
</script>

<style lang="stylus">
@import '~@/assets/styles/image-card.styl'
</style>
