<template>
    <div>
        <portal to="toolbar">
            <v-tabs v-model="tab" class="gray-tabs"
                    slider-color="orange"
                    background-color="blue-grey lighten-5">
                <v-tab to="#main">{{ $t(`tabs['photographer']`) }}</v-tab>
                <v-tab to="#admin">{{ $t(`tabs['admin']`) }}</v-tab>
                <v-tab to="#owner">{{ $t(`tabs['landlord']`) }}</v-tab>
            </v-tabs>
        </portal>

        <v-tabs-items v-model="tab">
            <v-tab-item id="main" :transition="false" :reverse-transition="false">
                <div v-if="loading" class="d-flex justify-center mt-8 mb-8">
                    <v-progress-circular
                        :size="50"
                        color="blue-grey darken-4"
                        indeterminate/>
                </div>
                <v-container v-show="!loading" fluid>
                    <h4 class="headline mt-6">{{ $t(`headers['photographer places']`) }}</h4>
                    <div class="mt-8 mb-8 text-center" v-show="!getMyEmployedPlaces.length">
                        <h4 class="headline">{{ $t(`messages['not photographer']`) }}</h4>
                    </div>

                    <places-grid v-show="getMyEmployedPlaces.length" v-model="getMyEmployedPlaces"/>
                </v-container>
            </v-tab-item>

            <v-tab-item id="admin" :transition="false" :reverse-transition="false">
                <v-container fluid>
                    <div class="mt-8 mb-8 text-center" v-show="!adminPlaces.length">
                        <h4 class="headline">{{ $t(`messages['not admin']`) }}</h4>
                    </div>
                    <div v-show="adminPlaces.length">
                        <h4 class="headline mt-6">{{ $t(`headers['admin places']`) }}</h4>
                        <places-grid v-model="adminPlaces"/>
                    </div>
                </v-container>
            </v-tab-item>

            <v-tab-item id="owner" :transition="false" :reverse-transition="false">
                <v-container fluid>
                    <div class="mt-8 mb-8 text-center" v-show="!ownedPlaces.length">
                        <h4 class="headline">{{ $t(`messages['not landlord']`) }}</h4>
                    </div>
                    <div v-show="ownedPlaces.length">
                        <h4 class="headline mt-6">{{ $t(`headers['my places']`) }}</h4>
                        <places-grid v-model="ownedPlaces"/>
                    </div>
                </v-container>
            </v-tab-item>

        </v-tabs-items>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import PlacesGrid from "../components/place/Grid"

export default {
    components: { PlacesGrid },
    head() {
        return {
            title: this.$t('titles.places'),
            toolbar: this.$t('toolbar.places'),
        }
    },
    data() {
        return {
            tab: this.$route.hash || "main",
            loading: false,
        }
    },
    // eslint-disable-next-line no-unused-vars
    fetch({ store, redirect }) {
        if (!store.getters["common/isAuthorized"]) {
            return redirect("/login")
        }
        // if (!store.getters["common/isPlaceAdmin"]) {
        //   return error({ statusCode: 403, message: "Forbidden" })
        // }

        return Promise.all([
            store.dispatch("user/storeMyEmployedPlaces"),
            store.dispatch("user/getMyPlaces"),
        // store.dispatch("user/storeMyInvitations"),
        ])
    },
    computed: {
        ...mapGetters("user", ["adminPlaces", "getMyInvites", "ownedPlaces", "getMyEmployedPlaces"]),
    },
}
</script>
